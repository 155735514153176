import { useReducer, useState, useEffect, useContext, useCallback } from 'react'
import {
    UniversalDataTable,
    UniversalModal,
    UniversalToolBar,
    UniversalLoadingCircle,
    UniversalCookieCrumb,
    DataGridLayout,
    FormSection,
} from '../../UniversalComponents'
import { ToastMessage } from '../../../context/ToastPopUpContext'
import { Grid, Typography } from '@mui/material'
import DevicesForm from './DevicesForm'
import {
    Archive as ArchiveIcon,
    ArrowOutward as ArrowOutwardIcon,
    AddToQueue as AddToQueueIcon,
    Restore,
    DeleteForever,
} from '@mui/icons-material/'
import { getDevices, deactivateDevices } from '../../../apiRoutes/deviceRoutes'
import { useMinimizer } from '../../../context/MinimizeContext'
import { usePermissions, useAuth } from '../../../context/AuthenticationContext'
import {
    permissionsCheck,
    handleActivation,
    handleDeletion,
    standardColumnMapper,
} from '../../../utilities/functionTools'
import { deleteDevice } from '../../../apiRoutes'

const Devices = () => {
    const setToastMessage = useContext(ToastMessage)
    const userPermissions = usePermissions()
    const currentUser = useAuth()
    const { setUser } = useAuth()
    const { userRole, activeManufacturer, userPreferences, _id } =
        currentUser.auth

    const defaultState = {
        open: false,
        row: {},
        data: [],
        viewDeactivated: false,
        formCache: {},
        activateData: [],
        devices: {
            columns: [
                {
                    field: 'manufacturerName',
                    headerName: 'MANUFACTURER',
                    width: 300,
                },
                { field: 'iccid', headerName: 'ICCID', width: 150 },
                { field: 'fullHin', headerName: 'HIN', width: 150 },
                { field: 'partNumber', headerName: 'PART NUMBER', width: 150 },
                {
                    field: 'deviceSerial',
                    headerName: 'DEVICE SERIAL',
                    width: 400,
                },
            ],
            density: 'standard',
        },
        density:
            userPreferences?.dataTableColumns?.devices?.density || 'standard',
    }
    const reducer = (state, newState) => ({ ...state, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)
    const { setMinimized } = useMinimizer()

    const {
        open,
        row,
        data,
        activateData,
        viewDeactivated,
        formCache,
        devices,
        density,
    } = state

    const getData = async (active) => {
        try {
            const data = await getDevices(active)

            if (data && data.length !== 0) {
                const devices = standardColumnMapper(defaultColumns, data)

                setState({ data: devices })
            } else {
                setState({ data: [] })
            }
        } catch (err: any) {
            setToastMessage(err, 'error')
            console.error(err, 'error')
        }
    }

    const collectColumnData = () => {
        const devices = userPreferences?.dataTableColumns?.devices?.columns

        if (Array.isArray(devices)) {
            const mapColumns = devices.map((device) => ({
                field: device?.field,
                headerName: device?.headerName,
                width: device?.width,
                isVisible: device?.isVisible,
            }))

            const format = { columns: mapColumns }

            setState({ ...state, devices: format })
        } else {
            console.log('No Devices Found')
        }
    }

    const submitColumnData = async () => {
        try {
            setUser({
                key: 'devices',
                value: devices,
                type: 'columnData',
            })
        } catch (err: any) {
            console.error(err, 'error')
            setToastMessage(`${err}`, 'error')
        }
    }

    const handleActivateData = () => {
        handleActivation({
            activateData,
            data,
            returnKey: 'deviceSerial',
            setState,
            getData,
            route: deactivateDevices,
            viewDeactivated,
            setToastMessage,
            page: 'devices',
        })
    }

    const handleDeletionData = () => {
        handleDeletion({
            activateData,
            data,
            returnKey: 'deviceSerial',
            setState,
            getData,
            route: deleteDevice,
            viewDeactivated,
            setToastMessage,
            page: 'devices',
        })
    }

    const handleDeletionCheck = () => {
        setToastMessage(
            `Are you sure you want to delete these Devices?`,
            'warning',
            handleDeletionData
        )
    }

    const setDeactivatedView = () => {
        setState({ viewDeactivated: !viewDeactivated })
    }

    useEffect(() => {
        collectColumnData()
        getData(true)
    }, [])

    // useEffect(() => {
    //     console.log('DataGrid component mounted')
    //     return () => {
    //         submitColumnData()
    //         console.log('DataGrid component unmounted')
    //     }
    // }, []) this might be added later as a quality of life adjustment for userface ease

    useEffect(() => {
        getData(!viewDeactivated)
    }, [viewDeactivated, activeManufacturer, userRole])

    const defaultColumns = [
        'deviceSerial',
        'manufacturer.manufacturerName',
        'iccid',
        'boat.fullHin',
        'part.partNumber',
        'id',
        'provider',
        'part.partDescription',
        'part._id',
        'boat.shortHin',
        'archived',
        'active',
    ]

    const visibleColumns = [
        'deviceSerial',
        'manufacturerName',
        'iccid',
        'fullHin',
        'partNumber',
    ]

    const customColumnNames = [
        'Device Serial',
        'Manufacturer',
        'ICCID',
        'HIN',
        'Part Number',
    ]

    const setModalVisbility = async (tableRow: any, newData: any) => {
        if (tableRow?.deviceSerial) {
            setState({ row: tableRow, open: !open })
        } else {
            setState({
                row: defaultState.row,
                open: !open,
            })
        }
        if (newData !== undefined) {
            return getData(true)
        }
    }

    const minimizeModal = () => {
        setMinimized(true, {
            title: formCache.deviceSerial
                ? `Edit: ${formCache.deviceSerial}`
                : 'Add New Device',
            link: 'admin/devices',
            data: formCache.id ? formCache : { ...formCache, id: 'newDevice' },
            id: formCache.id ? formCache.id : 'newDevice',
        })
        setState({ open: false, row: defaultState.row })
    }

    const buttons = [
        {
            buttonName: 'Add New Device',
            buttonIcon: <AddToQueueIcon />,
            buttonFunction: () => setState({ open: !open }),
            disabled: permissionsCheck(userPermissions, 'Devices'),
        },
        {
            buttonName: !viewDeactivated ? 'Deactivate' : 'Activate',
            buttonIcon: <ArchiveIcon />,
            buttonFunction: handleActivateData,
            disabled: permissionsCheck(userPermissions, 'Devices'),
        },
        {
            buttonName: viewDeactivated
                ? 'View Active Devices'
                : 'View Deactivated Devices',
            buttonIcon: <Restore />,
            buttonFunction: setDeactivatedView,
        },
        {
            buttonName: 'Delete',
            buttonIcon: <DeleteForever />,
            buttonFunction: handleDeletionCheck,
            disabled: permissionsCheck(userPermissions, 'Super Admin'),
            display: !viewDeactivated ? 'none' : null,
        },
        // {
        //     buttonName: 'Export',
        //     buttonIcon: <ArrowOutwardIcon />,
        //     buttonFunction: () => {},
        // },
    ]

    const handleUpdatedColumnsChange = (updatedColumns) => {
        const format = updatedColumns.map((col) => ({
            field: col.field,
            headerName: col.headerName,
            width: col.width,
            isVisible: col.isVisible,
        }))

        const setDensity = state.density

        const setDevices = { columns: format, density: setDensity }

        setState({
            devices: setDevices,
        })
    }

    const handleUpdatedVisibility = (columnVisibilityModel) => {
        const updatedVisibility = devices?.columns.reduce((acc, col) => {
            if (col.field in columnVisibilityModel) {
                acc[col.field] = {
                    ...col,
                    isVisible: columnVisibilityModel[col.field],
                }
            } else {
                acc[col.field] = col
            }

            return acc
        }, {})

        const updatedColumns = Object.values(updatedVisibility)

        const setDensity = state.density

        const setDevices = { columns: updatedColumns, density: setDensity }

        setState({ ...state, devices: setDevices })
    }

    const handleDensityChange = (newDensity) => {
        setState({
            density: newDensity,
            devices: {
                ...devices,
                density: newDensity,
            },
        })
    }

    return (
        <Grid container spacing={2}>
            <DataGridLayout
                cookieCrumbs={<UniversalCookieCrumb />}
                toolbar={<UniversalToolBar buttons={buttons} />}
                tabBar={
                    <FormSection>
                        <Grid item xs={12}>
                            <Typography
                                variant="h4"
                                color={'primary'}
                                align="center"
                                sx={{ marginTop: -1 }}
                            >
                                {!viewDeactivated
                                    ? 'Devices'
                                    : 'Deactivated Devices'}
                            </Typography>
                        </Grid>
                    </FormSection>
                }
                dataGrid={
                    <UniversalLoadingCircle
                        data={data}
                        reloadFunction={!viewDeactivated ? getData : () => {}}
                        customTimeoutMessage={
                            !!viewDeactivated &&
                            'No Deactivated Devices Available'
                        }
                    >
                        <UniversalDataTable
                            data={data}
                            apiDensity={density}
                            apiColumns={devices?.columns}
                            savePreferences={() => submitColumnData()}
                            visibleColumns={[]}
                            customColumnNames={customColumnNames}
                            getRowData={(tableRow) =>
                                setModalVisbility(tableRow, undefined)
                            }
                            onRowSelectionModelChange={(newSelection) => {
                                setState({
                                    activateData: newSelection,
                                })
                            }}
                            onUpdatedColumnsChange={handleUpdatedColumnsChange}
                            getColumnHeaderVisibility={handleUpdatedVisibility}
                            onDensityChange={handleDensityChange}
                        />
                    </UniversalLoadingCircle>
                }
            />
            <UniversalModal
                title={`${
                    row.deviceSerial
                        ? `Device Serial : ${row.deviceSerial}`
                        : 'Configure A Telematics Device'
                }`}
                open={open}
                onClose={(tableRow) => setModalVisbility(tableRow, undefined)}
                wrapperStyle={{
                    margin: 'auto',
                    width: '90%',
                    marginTop: 5,
                }}
            >
                <DevicesForm
                    formTitle="Configure A Telematics Device"
                    row={row}
                    onClose={(tableRow: any, newData: any) =>
                        setModalVisbility(tableRow, newData)
                    }
                    saveCache={(data: any) => setState({ formCache: data })}
                    minimizeModal={minimizeModal}
                    permissionCheck={permissionsCheck(
                        userPermissions,
                        'Devices'
                    )}
                    quickStart={false}
                />
            </UniversalModal>
        </Grid>
    )
}

export default Devices
