import { useContext, useEffect, useReducer } from 'react'
import {
    FormControlLabel,
    Switch,
    Grid,
    TextField,
    styled,
    IconButton,
    Box,
    Tooltip,
    Chip,
} from '@mui/material'

import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { datePickerConverter } from '../../../../../utilities/functionTools'
import { ToastMessage } from '../../../../../context/ToastPopUpContext'
import { Authentication } from '../../../../../context/AuthenticationContext'
import { getManufacturers } from '../../../../../apiRoutes'
import { parseFormWithFile } from '../../../../../utilities/functionTools'
import UniversalTagToolbar from '../../../../UniversalComponents/UniversalTagToolbar'
import { FormSection, SaveButton } from '../../../../UniversalComponents'
import responseError from '../../../../../context/responseError/responseError'
import { basicValidator } from '../../../../../utilities/functionTools'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import FilePresentIcon from '@mui/icons-material/FilePresent'

const EngineCalibrationForm = ({
    row,
    onClose,
    quickStart,
    addCalibrationFile,
}) => {
    const setToastMessage = useContext(ToastMessage)
    const currentUser = useContext(Authentication)
    const { username } = currentUser.auth

    const defaultState = {
        formData: {
            id: row?.id ? row?.id : `${Math.random()}`,
            engineCalibrationECMPartNumber:
                row?.engineCalibrationECMPartNumber || '',
            engineCalibrationFileUrl: row?.engineCalibrationFileUrl || '',
            engineCalibrationFileName: row?.engineCalibrationFileName || '',
            engineCalibrationProductionDate:
                row?.engineCalibrationProductionDate
                    ? datePickerConverter(row?.engineCalibrationProductionDate)
                    : '',
            engineCalibrationRevision: row?.engineCalibrationRevision || '',
            engineCalibrationEControlsSoftwareRevision:
                row?.engineCalibrationEControlsSoftwareRevision || '',
            engineCalibrationSoftwareModel:
                row?.engineCalibrationSoftwareModel || '',
            engineCalibrationChecksum: row?.engineCalibrationChecksum || '',
            engineCalibrationReleaseNotes:
                row?.engineCalibrationReleaseNotes || '',
            engineCalibrationApproved: row?.engineCalibrationApproved || false,
            engineCalibrationActive: row?.engineCalibrationActive || false,
        },

        formValidation: {
            calibrationFileNameValidation: false,
            engineCalibrationECMPartNumberValiation: false,
            custSoftwarePartNumberValidation: false,
            deploayedAtValidation: false,
            manufacturerNameValidation: false,
            isFormDirty: false,
            isFormValid: false,
        },

        selectedFile: { name: row.calibrationFileName || undefined },
        setVersionNameInput: '',
        closeModal: false,
        calibrationFileNameDisabled: row.calibrationFileName ? true : false,
        manufacturerChoiceDisabled: row.manufacturerName ? true : false,
        manufacturerOptions: [],
        manufacturers: [],
    }

    const reducer = (state, newState) => ({ ...state, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)

    const { formData, selectedFile, formValidation, manufacturers } = state

    const {
        engineCalibrationECMPartNumber,
        engineCalibrationFileUrl,
        engineCalibrationFileName,
        engineCalibrationProductionDate,
        engineCalibrationRevision,
        engineCalibrationEControlsSoftwareRevision,
        engineCalibrationSoftwareModel,
        engineCalibrationChecksum,
        engineCalibrationReleaseNotes,
        engineCalibrationApproved,
        engineCalibrationActive,
    } = formData

    const {
        calibrationFileNameValidation,
        engineCalibrationECMPartNumberValidation,
        custSoftwarePartNumberValidation,
        manufacturerNameValidation,
        isFormDirty,
        isFormValid,
    } = formValidation

    const getEngineManufacturerData = async () => {
        try {
            const data = await getManufacturers(true)
            if (data && data.length !== 0) {
                setState({
                    manufacturerOptions: data
                        .filter(
                            (manufacturer) => manufacturer.type === 'engine'
                        )
                        .map(({ manufacturerName }) => manufacturerName),
                    manufacturers: data.filter(
                        (manufacturer) => manufacturer.type === 'engine'
                    ),
                })
            }
        } catch (err: any) {
            setToastMessage(err, 'error')
            console.error(err, 'error')
        }
    }

    const handleSubmit = async () => {
        delete formData.updatedAt

        const newFormData = { ...formData }

        const parsedFormData = parseFormWithFile(newFormData, [selectedFile])
        addCalibrationFile(newFormData, parsedFormData)
        onClose(newFormData)
    }

    const handleChange = (e: any) => {
        const { name, value } = e.target
        if (name === 'manufacturerName') {
            const newEngineManufacturerId = manufacturers.find(
                (file) => file.manufacturerName === value
            )

            setState({
                formData: {
                    ...formData,
                    manufacturer: newEngineManufacturerId
                        ? newEngineManufacturerId._id
                        : '',
                },
            })
        } else {
            setState({
                formData: { ...formData, [name]: value },
            })
        }
    }

    const handleFileSelect = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0]

            setState({
                selectedFile: file,
                formValidation: { ...formValidation, isFormDirty: true },
            })
        }
    }

    const handleFileRemove = () => {
        setState({
            selectedFile: [],
        })
    }

    const handleReset = () => {
        if (row.calibrationFileName) {
            setState({
                selectedFile: { name: row.calibrationFileName || undefined },
                formData: defaultState.formData,
                formValidation: defaultState.formValidation,
            })
        } else {
            setState({
                formData: defaultState.formData,
                formValidation: defaultState.formValidation,
                selectedFile: '',
            })
        }
    }

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    })

    useEffect(() => {
        getEngineManufacturerData()
    }, [])

    useEffect(() => {
        if (
            isFormDirty &&
            !calibrationFileNameValidation &&
            !custSoftwarePartNumberValidation &&
            engineCalibrationFileUrl &&
            selectedFile.name &&
            selectedFile.length !== 0
        ) {
            setState({
                formValidation: {
                    ...formValidation,
                    isFormValid: true,
                },
            })
        } else {
            setState({
                formValidation: {
                    ...formValidation,
                    isFormValid: false,
                },
            })
        }
    }, [
        isFormDirty,
        calibrationFileNameValidation,
        custSoftwarePartNumberValidation,
        engineCalibrationFileUrl,
        selectedFile,
    ])

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={7}>
                <FormSection title="Engine Calibration File Details">
                    <Grid item xs={12}>
                        <TextField
                            required={true}
                            name="engineCalibrationECMPartNumber"
                            label="ECM Part Number"
                            value={engineCalibrationECMPartNumber}
                            fullWidth
                            onChange={(event) => {
                                const newValue = event.target.value
                                const data = {
                                    target: {
                                        name: 'engineCalibrationECMPartNumber',
                                        value:
                                            typeof newValue === 'string'
                                                ? newValue
                                                : '',
                                    },
                                }
                                handleChange(data)
                            }}
                            onBlur={(e: any) =>
                                basicValidator(e, formValidation, setState)
                            }
                            error={engineCalibrationECMPartNumberValidation}
                            helperText={
                                engineCalibrationECMPartNumber
                                    ? 'Please enter a enter a notification name.'
                                    : ''
                            }
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            name="engineCalibrationFileName"
                            label="EMOT File Name"
                            value={engineCalibrationFileName}
                            fullWidth
                            onChange={handleChange}
                        />
                    </Grid>
                </FormSection>
            </Grid>
            <Grid item xs={12} md={5}>
                <FormSection title="Engine Calibration File Upload">
                    <Grid item xs={12} sx={{ paddingBottom: 0.7 }}>
                        <label htmlFor="raised-button-file">
                            <Tooltip
                                title={
                                    engineCalibrationFileName ? (
                                        engineCalibrationFileUrl ? (
                                            <h3 style={{ color: 'primary' }}>
                                                Replace Calibration File
                                            </h3>
                                        ) : (
                                            <h3 style={{ color: 'inherit' }}>
                                                Upload Default Calibration File
                                            </h3>
                                        )
                                    ) : (
                                        <h3 style={{ color: 'inherit' }}>
                                            Please Add a Calibration File Name
                                            Before Uploading a Calibration File
                                        </h3>
                                    )
                                }
                            >
                                <center>
                                    {engineCalibrationFileUrl ? (
                                        <IconButton component="label">
                                            <Box>
                                                <UploadFileIcon
                                                    sx={{
                                                        fontSize: 90,
                                                    }}
                                                />
                                                <VisuallyHiddenInput
                                                    onChange={handleFileSelect}
                                                    type="file"
                                                    accept="image/*"
                                                    id='htmlFor="raised-button-file'
                                                />
                                            </Box>
                                        </IconButton>
                                    ) : (
                                        <IconButton component="label">
                                            <DriveFolderUploadIcon
                                                sx={{
                                                    fontSize: 90,
                                                }}
                                            />
                                            <VisuallyHiddenInput
                                                onChange={handleFileSelect}
                                                disabled={
                                                    !engineCalibrationFileName
                                                }
                                                type="file"
                                                accept="image/*"
                                                id='htmlFor="raised-button-file'
                                            />
                                        </IconButton>
                                    )}
                                </center>
                            </Tooltip>
                        </label>
                    </Grid>
                    <Grid item xs={12}>
                        {selectedFile?.name && (
                            <Chip
                                label={selectedFile?.name}
                                variant="outlined"
                                color="info"
                                icon={
                                    <FilePresentIcon
                                        sx={{
                                            color: 'primary',
                                        }}
                                    />
                                }
                                onDelete={() => {
                                    handleFileRemove()
                                    setState({
                                        formValidation: {
                                            ...formValidation,
                                            isFormDirty: true,
                                        },
                                    })
                                }}
                                sx={{
                                    color: 'grey',
                                    fontSize: 25,
                                    borderRadius: 0,
                                }}
                                deleteIcon={<RemoveCircleIcon />}
                            />
                        )}
                    </Grid>
                </FormSection>
            </Grid>

            <Grid item xs={12} sx={{ marginTop: -2 }}>
                <FormSection title="Deployment & Production">
                    <Grid item xs={12} md={2}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Production Date"
                                views={['year', 'month', 'day']}
                                value={engineCalibrationProductionDate}
                                onChange={({ $d }) => {
                                    const event = {
                                        target: {
                                            value: $d.toDateString(),
                                            name: 'engineCalibrationProductionDate',
                                        },
                                    }
                                    setState({
                                        formValidation: {
                                            ...formValidation,
                                            isFormDirty: true,
                                        },
                                    })
                                    handleChange(event)
                                }}
                                sx={{ width: '100%' }}
                                slotProps={{
                                    textField: {
                                        error: false,
                                    },
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <TextField
                            name="engineCalibrationRevision"
                            label="Rev"
                            value={engineCalibrationRevision}
                            fullWidth
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <TextField
                            name="engineCalibrationSoftwareModel"
                            label="Software Model"
                            value={engineCalibrationSoftwareModel}
                            fullWidth
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            name="engineCalibrationEControlsSoftwareRevision"
                            label="Econtrols Software Revision"
                            value={engineCalibrationEControlsSoftwareRevision}
                            fullWidth
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <TextField
                            name="engineCalibrationChecksum"
                            label="Checksum"
                            value={engineCalibrationChecksum}
                            fullWidth
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <TextField
                            name="engineCalibrationReleaseNotes"
                            label="Release Notes"
                            value={engineCalibrationReleaseNotes}
                            fullWidth
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <FormControlLabel
                            control={
                                <Switch
                                    onChange={() =>
                                        setState({
                                            formData: {
                                                ...formData,
                                                engineCalibrationActive:
                                                    !engineCalibrationActive,
                                            },
                                            formValidation: {
                                                ...formValidation,
                                                isFormDirty: true,
                                            },
                                        })
                                    }
                                    inputProps={{
                                        'aria-label': 'controlled',
                                    }}
                                    id="switch.engineCalibrationActive"
                                    name="switch.engineCalibrationActive"
                                    checked={engineCalibrationActive}
                                    key="switch.engineCalibrationActive"
                                />
                            }
                            label="Engine Calibration Active"
                            key="switch.engineCalibrationActive"
                        />
                    </Grid>
                </FormSection>

                <Grid item xs={12}>
                    <SaveButton
                        handleSubmit={handleSubmit}
                        handleReset={handleReset}
                        disabledSave={!isFormDirty}
                        disabledReset={!isFormDirty}
                        quickStart={quickStart}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default EngineCalibrationForm
