import { useReducer, useRef, useEffect } from 'react'
import { useSocket } from '../../context/SocketContext'
import {
    FormSection,
    UniversalToolBar,
    UniversalCookieCrumb,
    UniversalModal,
} from '../UniversalComponents'
import { Grid, TextField, Button, Typography } from '@mui/material'
import { AddBox, Bolt } from '@mui/icons-material'
import {
    SleepingPieChart,
    ModelsBarGraph,
    EngineModelsBarGraph,
    EngineHoursHistogram,
} from './ChartJsGraphics'
import { useQuickStartWizard } from '../../context/QuickStartWizard'

const Home = () => {
    const defaultState = {
        telemetry: [],
    }
    const reducer = (state, newState) => ({ ...state, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)

    const { telemetry } = state

    const { socket } = useSocket()

    const refreshCount = useRef(0)
    const interval = useRef<any>()
    const { setQuickStartWizard } = useQuickStartWizard()

    useEffect(() => {
        socket.emit('allFleetLiveData', { userRole: 'Super Admin' })
        socket.on('allFleetLiveDataRes', (data) => {
            if (data) {
                setState({
                    telemetry: data.filter(
                        (obj: any) => obj.redisObj !== 'noRedisObj'
                    ),
                })
            }
            refreshCount.current = refreshCount.current + 1
            return refreshCount
        })

        return () => socket.emit('leave:room', '')
    }, [])

    useEffect(() => {
        interval.current = setInterval(() => {
            socket.emit('allFleetLiveData', { userRole: 'Super Admin' })
            socket.on('allFleetLiveDataRes', (data) => {
                if (data) {
                    setState({
                        telemetry: data.filter(
                            (obj: any) => obj.redisObj !== 'noRedisObj'
                        ),
                    })
                }
                refreshCount.current = refreshCount.current + 1
                return refreshCount
            })

            return () => socket.emit('leave:room', '')
        }, 10000)

        return () => {
            clearInterval(interval.current)
        }
    }, [interval])

    return (
        <Grid container spacing={1} sx={{ marginTop: 10 }}>
            <Grid
                container
                spacing={2}
                sx={{ alignContent: 'center', justifyContent: 'center' }}
            >
                <Grid item xs={3}>
                    <Typography variant="h4" color="primary" align="center">
                        Get started by provisioning a boat or engine
                    </Typography>
                </Grid>

                <Grid item xs={2}>
                    <Button
                        fullWidth
                        style={{ height: 55, position: 'relative', bottom: 5 }}
                        variant="contained"
                        color="primary"
                        onClick={() => setQuickStartWizard(true)}
                        startIcon={
                            <Bolt
                                style={{
                                    fontSize: 25,
                                }}
                            />
                        }
                    >
                        Quick Start Provision Wizard
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <FormSection title="Chart JS">
                    <Grid item xs={6}>
                        <Grid container>
                            {telemetry && (
                                <SleepingPieChart
                                    telemetry={telemetry.map((boat: any) => ({
                                        hin: boat._doc.fullHin,
                                        boatStatus: boat.redisObj._boatStatus,
                                    }))}
                                />
                            )}
                        </Grid>
                    </Grid>
                    {/* <Grid item xs={5} sx={{ marginTop: '10%' }}>
                        <Grid container>
                            {telemetry && (
                                <EngineHoursHistogram
                                    telemetry={telemetry.map((boat: any) => ({
                                        hin: boat._doc.fullHin,
                                        engineHours:
                                            boat.redisObj.Totalenginehours_0,
                                    }))}
                                />
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container>
                            {telemetry && (
                                <ModelsBarGraph
                                    telemetry={telemetry.map((boat: any) => ({
                                        hin: boat._doc.fullHin,
                                        model: boat._doc.boatModel.model,
                                    }))}
                                />
                            )}
                        </Grid>
                    </Grid>
                    {/* <Grid item xs={6}>
                        <Grid container>
                            {telemetry && (
                                <EngineModelsBarGraph
                                    telemetry={telemetry.map((boat: any) => ({
                                        hin: boat._doc.fullHin,
                                        engineModel:
                                            boat._doc.engine.engineName,
                                    }))}
                                />
                            )}
                        </Grid>
                    </Grid> */}
                </FormSection>
            </Grid>
        </Grid>
    )
}

export default Home
